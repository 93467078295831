import React, { ReactElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomers } from '@state/customer/actions';
import { AppState } from '@state/reduxTypes';
import { getCustomersFromState } from '@state/customer/selectors';
import TableHeader from '@components/common/TableHeader';
import { getColumns } from './CustomersList.definition';
import TableView from '../common/TableView';

const Customers = (): ReactElement => {
  const dispatch = useDispatch();
  const customers = useSelector(getCustomersFromState);
  const isLoading = useSelector((state: AppState) => state.customerState.isLoading);
  const getData = () => {
    dispatch(getCustomers());
  };

  const tableProps = {
    data: customers,
    getColumns,
    getData,
    isLoading,
  };

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  return (
    <>
      <TableHeader
        title="Customers"
        kind="WITH_REFRESH"
        refreshButtonText="Reload Customers"
        onRefreshClick={() => dispatch(getCustomers())}
      />
      <TableView {...tableProps} pagination={{ position: ['bottomCenter'] }} />
    </>
  );
};

export default Customers;
