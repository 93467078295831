import { message } from 'antd';
import { CreateCustomerData, Customer } from '@beacon-devops/customer-service-client';
import CustomerService from '@services/CustomerService';
import { CustomerActions } from './constants';
import { Action, AsyncDispatch } from '../reduxTypes';

export const setCustomer = (customer?: Customer): Action => ({
  type: CustomerActions.SET_CUSTOMER,
  payload: customer,
});

export const setCustomers = (customers: Customer[]): Action => ({
  type: CustomerActions.SET_CUSTOMERS,
  payload: customers,
});

export const customerLoading = (loading: boolean) => ({
  type: CustomerActions.CUSTOMER_LOADING,
  payload: loading,
});

export const customerSuccess = () => ({
  type: CustomerActions.CUSTOMER_SUCCESS,
});

export const customerError = () => ({
  type: CustomerActions.CUSTOMER_ERROR,
});

export const createCustomerSuccess = () => ({
  type: CustomerActions.CREATE_CUSTOMER_SUCCESS,
});

export const clearCustomer = () => ({
  type: CustomerActions.CLEAR_CUSTOMER,
});

export const getCustomer = (customerId: string) => (dispatch: AsyncDispatch) => {
  dispatch(customerLoading(true));
  CustomerService.getCustomer(customerId)
    .then((customer) => dispatch(setCustomer(customer)))
    .catch((error) => {
      message.error(`Get customer failed ${error.toString()}`, 5);
      dispatch(customerError());
    })
    .finally(() => dispatch(customerLoading(false)));
};

export const getCustomers = (isTest?: boolean) => (dispatch: AsyncDispatch) => {
  dispatch(customerLoading(true));
  CustomerService.getAll(isTest)
    .then((customers) => dispatch(setCustomers(customers)))
    .catch((error) => {
      message.error(`Get customer list failed ${error.toString()}`, 5);
      dispatch(customerError());
    })
    .finally(() => dispatch(customerLoading(false)));
};

export const updateCustomer = (customerId: string, version: string, customerData: CreateCustomerData) => (
  dispatch: AsyncDispatch,
) =>
  CustomerService.updateCustomer(customerId, version, customerData)
    .then((customer) => {
      message.success('Customer updated', 5);
      dispatch(setCustomer(customer));
    })
    .catch((error) => {
      message.error(`Update customer failed ${error.toString()}`, 5);
      dispatch(customerError());
    })
    .finally(() => dispatch(customerLoading(false)));

export const createCustomer = (customerData: CreateCustomerData) => (dispatch: AsyncDispatch) => {
  dispatch(customerLoading(true));
  CustomerService.createCustomer(customerData)
    .then(() => {
      message.success('Customer created', 5);
      dispatch(createCustomerSuccess());
      dispatch(customerLoading(false));
    })
    .catch((error) => {
      message.error(`Create customer failed ${error.toString()}`, 5);
      dispatch(customerError());
      dispatch(customerLoading(false));
    })
    .finally(() => dispatch(customerLoading(false)));
};
