import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Card, List, Empty, Row, Col, Spin, Tooltip, Tag, Button, Popconfirm } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, FormOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import { Customer, Role } from '@beacon-devops/customer-service-client';
import { deleteSelectedUser, sendInviteEmail } from '@state/user/actions';
import CustomerService from '@services/CustomerService';
import UserService from '@services/User';
import { User } from '@services/constants';
import { USER_UPDATE_ROUTE, USERS_ROUTE } from '@routes/constants';
import { UserWithRoles } from '@beacon-types/customer';
import { ActiveTagTemplate, RoleListTemplate, StatusTagTemplate } from '../../UsersList/partials';

const { Title } = Typography;

interface UserDetailProps {
  userId?: string;
  customerId: string;
}

interface ListItem {
  label: string;
  value: string | ReactElement;
}

const UserDetail = ({ customerId, userId }: UserDetailProps): ReactElement => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<UserWithRoles>({} as UserWithRoles);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([] as Customer[]);
  const history = useHistory();

  const userRoles = _get(user, 'permissions', []);

  const initializeFormData = async () => {
    setIsLoading(true);
    const user = userId ? await UserService.getUserById(userId) : await Promise.resolve({} as User);
    const roles =
      customerId && userId ? await UserService.getUserRoles(customerId, userId) : await Promise.resolve([] as Role[]);
    setUser({ ...user, permissions: roles });

    const customers = await CustomerService.getAll();
    setCustomers(customers);

    setIsLoading(false);
  };

  useEffect(() => {
    initializeFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Row justify="center">
        <Spin size="large" />
      </Row>
    );
  }

  if (!userId || !customerId || !customers || !user) {
    return <Empty />;
  }

  const companyName = customers.find((customer) => customer.customerId === `bcn1.c.customer.${user.companyId}`)
    ?.fullCompanyName;

  const getUserDetails = (): ListItem[] => [
    { label: 'Email', value: user.email || '-' },
    {
      label: 'Company Name',
      value: (
        <Row>
          <Typography>{companyName}</Typography>
          {user.isTest && (
            <Tag color="orange" style={{ marginLeft: '8px', marginRight: '0px' }}>
              Test company
            </Tag>
          )}
        </Row>
      ),
    },
    { label: 'First Name', value: user.firstName || '-' },
    { label: 'Last Name', value: user.lastName || '-' },
    {
      label: 'User Group',
      value: user.userGroup ? user.userGroup : '-',
    },
    {
      label: 'Roles',
      value: user.userGroup ? <RoleListTemplate roles={userRoles} /> : '-',
    },
    {
      label: 'Status',
      value: <StatusTagTemplate user={user} /> || '-',
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: '32px' }}>
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle" justify="start">
              <Col>
                <Link className="ant-btn ant-btn-text" to={USERS_ROUTE}>
                  <ArrowLeftOutlined />
                </Link>
              </Col>
              <Col>
                <Title level={3} style={{ margin: 0 }}>
                  User View
                </Title>
              </Col>
            </Row>
          </Col>
          {user && (
            <Col>
              <Tooltip placement="bottom" title="Edit User">
                <Link
                  to={USER_UPDATE_ROUTE.replace(':customerId', user.companyId).replace(':userId', userId)}
                  className="ant-btn ant-btn-text"
                >
                  <FormOutlined />
                </Link>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Card>
      <Card>
        <List
          header={
            <Row align="middle" justify="space-between">
              <Col>
                <h4 className="ant-typography">User Details</h4>
              </Col>
              {!user.isActive && <Col>{ActiveTagTemplate(user)}</Col>}
            </Row>
          }
          dataSource={getUserDetails()}
          renderItem={(item: ListItem) => (
            <List.Item>
              <Typography.Text strong>{item.label}</Typography.Text>
              <Typography.Text>{item.value}</Typography.Text>
            </List.Item>
          )}
          style={{ marginBottom: '16px' }}
        />

        {user && user.isActive && (
          <>
            <Row justify="end" style={{ marginTop: '40px' }}>
              <Button type="primary" onClick={() => dispatch(sendInviteEmail(customerId, userId))}>
                Re-send Invite Email
              </Button>
            </Row>
            <Row justify="end" style={{ marginTop: '40px' }}>
              <Popconfirm
                onConfirm={() => dispatch(deleteSelectedUser(userId, history))}
                title="Are you sure you want to delete this user?"
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger>
                  Delete User
                </Button>
              </Popconfirm>
            </Row>
          </>
        )}
      </Card>
    </>
  );
};

export default UserDetail;
